<template>
  <div class="recomeSerach">
    <Else></Else>
    <div class="recomeSerach-con">
      <div class="recomeSerach-middle">
        <div class="title">AI商标注册成功率评估</div>
        <div class="recomeSerach-inp">
            <input type="text" class="input1" v-model="brandName" placeholder="请输入商标名称"><input type="text" v-model="productName" class="input2" placeholder="请输入商品名称或服务">
            <span class="span1" @click="to">AI评估</span>
        </div>
        <div class="title-tip">本服务为社会公众提供商标相关信息查询，本站不负责其及时性、准确性判断,仅供参考，不具有法律效力。</div>
      </div>
    </div>
   
  </div>

</template>

<script>
import Else from '../ui/else.vue';
export default {
  name: 'recomeSerach',
  data(){
    return {
        brandName:'',
        productName:''
    }
  },
  components: {
    Else
  },
  watch:{
    
    
  },
  methods: {
    tip(type, msg) {
      return this.$message({
        type: type,
        message: msg,
        showClose: true,
        duration: 1500,
      });
    },
   to(){
    let reg = /^[0-9]*$/;
      let reg2 = /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/im;
      let reg3 =  /^[\u4e00-\u9fa5]+$/
       if (this.brandName == '') {
          this.tip('error', "请输入商标名称")
          return false
        } else if (reg.test(this.brandName)) {
          this.tip('error', "请输入汉字或字母")
          return false
        } else if (reg2.test(this.brandName)) {
          this.tip('error', "请输入正确的商标名称(不包括特殊字符)")
          return false
        }else if(this.productName == ""){
            this.tip('error', "请输入商品名称或服务")
          return false
        }else{
            this.$router.push({
                  path:'/recome',
                  query:{
                   brandName: this.brandName,
                   productName:this.productName
                  }
                })
        }
   }
  },
   mounted() {
     window.parent.postMessage(
                  {
                    data: 715,
                  },
                  "*"
                );
    }
}
</script>

<style scoped>
html,body,#app{
    margin: 0;
    padding: 0;
    height: 100%;
}
.recomeSerach-con{
    width: 100vw;
  height: 100vh;
  background: url('../../../static/images/bj77.png') 100% no-repeat;
  background-size: cover;
  position: relative;
}
.recomeSerach-middle{
    width: 1050px;
  height: 320px;
  /* border:1px solid red; */
  position: absolute;
  top:45%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.title{
    width: 1050px;
    color: #ffffff;
    font-size: 34px;
    text-align: center;
    margin-bottom: 50px;
}
input::-webkit-input-placeholder {    /* Chrome/Opera/Safari */
   color: rgb(220, 220, 220);
   font-weight: 300;
}
.recomeSerach-inp>input{
    outline: none;
    vertical-align: middle;
    height: 58px;
    padding-left: 5px;
    text-indent: 20px;
    letter-spacing: 2px;
    font: 20px "Arial", "Microsoft YaHei", "黑体", "宋体", sans-serif;
}
.input1{
    width: 392px;
    margin-right: 3px;
}
.input2{
    width: 490px;
}
.span1{
    width: 145px;
    height: 60px;
    vertical-align: middle;
    display: inline-block;
    background: #ff8328;
    border-radius: 0px 5px 5px 0px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 60px;
    text-align: center;
    cursor: pointer;
}
.title-tip{
  width: 1050px;
  text-align: center;
  font-size: 14px;
  margin-top: 60px;
  color: #FFFFFF;
  font-family: Microsoft YaHei;
}
</style>


